import React, {useState, useEffect} from "react";

import { translate, t } from "react-multi-lang";
import { Button, Container, Row, Col, Image, Nav } from "react-bootstrap";
import { connect } from "react-redux";
import {
  setTranslations,
  setDefaultLanguage,
  setLanguage,
  getLanguage,
} from "react-multi-lang";

const MainPageNew = (props) => {
  const [toggleMenu,setToggleMenu] = useState(false);
  const [toggleBurgerMenu,setToggleBurgerMenu] = useState(false);
  const [currentLanguage,setCurrentLanguage] = useState(getLanguage());

useEffect ( ()=>{
  if(!getLanguage()) setLanguage("en");

}, []);
  
  const toggleLanguageSwitcher = () =>{
    setToggleMenu(toggleMenu? false: true)
  }
  const toggleBurgerMenuMobile = () =>{
    setToggleBurgerMenu( toggleBurgerMenu? false: true);

  }

  const handleChangeLang = (language) => {
    if(language !== currentLanguage){
      console.log(";;;;",language);
      setLanguage(language);
      localStorage.setItem("lang", language);
      setCurrentLanguage(language)
    }else{
      console.log('samelang');
    }
    // window.location.reload();
  };
  return (
    <>
      <header  style={{ background: window.innerWidth <992 ? '#fff url(' +window.location.origin +'/assets/images/bg-hero.jpg) center center no-repeat' :currentLanguage === 'ara'? '#fff url(' +window.location.origin +'/assets/images/bg-hero.jpg) center left no-repeat' : '#fff url(' +window.location.origin +'/assets/images/bg-hero.jpg) center right no-repeat', backgroundSize: 'cover'}}>
        <Nav className="navbar navbar-expand-lg">
            <Container>
    
                <Button className={ ` navbar-toggler ${ currentLanguage=='ara' ? 'order-1' : 'order-2'} `} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" onClick={toggleBurgerMenuMobile}></span>
                </Button>
                <div className={ ` navbar-collapse ${ currentLanguage=='ara' ? 'order-lg-2 left-mobile-menu justify-content-end' : 'order-lg-1'} `} id="navbarSupportedContent" 
                  style={{
                    display: window.innerWidth > 992 ? 'flex':  toggleBurgerMenu ? 'flex': 'none',
                    visibility: window.innerWidth > 992 ? 'visible' :toggleBurgerMenu ? 'visible': 'hidden',
                    padding: 0}}>
                    <button 
                        type="button" 
                        className={`closeBtn d-lg-none ${currentLanguage === 'ara' ? 'topLeft' : 'topRight'}`} 
                        aria-label="Close"
                        onClick={toggleBurgerMenuMobile}
                    >
                        <i className="fa fa-times"></i>
                    </button>
                    <ul className="navbar-nav" style={{direction:  currentLanguage=='ara' ?'rtl': 'ltr'}} >
                        <li className={`nav-item ${ window.innerWidth <992 ? currentLanguage ==='ara' ? 'arabicmobilemenu': '':''}`}><a className={`nav-link ${currentLanguage === 'ara'?'arabicFont' :''}`} href="/page/about">{t("about_us")}</a></li>
                        <li className={`nav-item ${ window.innerWidth <992 ? currentLanguage ==='ara' ? 'arabicmobilemenu': '':''}`}><a className={`nav-link ${currentLanguage === 'ara'?'arabicFont' :''}`} href="/page/contact">{t("contact_us")}</a></li>
                        {/* <li className={`nav-item ${ window.innerWidth <992 ? currentLanguage ==='ara' ? 'arabicmobilemenu': '':''}`}><a className={`nav-link ${currentLanguage === 'ara'?'arabicFont' :''}`} href="/page/terms">{t("terms")}</a></li>
                        <li className={`nav-item ${ window.innerWidth <992 ? currentLanguage ==='ara' ? 'arabicmobilemenu': '':''}`}><a className={`nav-link ${currentLanguage === 'ara'?'arabicFont' :''}`} href="page/privacy">{t("privacy ")}</a></li> */}
                        {/* <li className={`nav-item ${ window.innerWidth <992 ? currentLanguage ==='ara' ? 'arabicmobilemenu': '':''}`}><a className={`nav-link ${currentLanguage === 'ara'?'arabicFont' :''}`} href="#">{t("creators")}</a></li> */}
                        {/* <li className={`nav-item ${ window.innerWidth <992 ? currentLanguage ==='ara' ? 'arabicmobilemenu': '':''}`}><a className={`nav-link ${currentLanguage === 'ara'?'arabicFont' :''}`} href="#">{t("our_team")}</a></li> */}
                        {/* <li className={`nav-item ${ window.innerWidth <992 ? currentLanguage ==='ara' ? 'arabicmobilemenu': '':''}`}><a className={`nav-link ${currentLanguage === 'ara'?'arabicFont' :''}`} href="#">{t("careers")}</a></li> */}
                        <li className={`nav-item ${ window.innerWidth <992 ? currentLanguage ==='ara' ? 'arabicmobilemenu': '':''}`} 
                          onClick={toggleLanguageSwitcher}
             
                         style={{
                            position: "relative",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            width: "max-content"
                          }
                        }>
                            <span className="selectedLanguage" >
                              <Image
                                  src={ currentLanguage == "en" ? 
                                    window.location.origin +"/assets/images/en.png":
                                    window.location.origin +"/assets/images/ar.png"
                                  }
                                  alt=""
                                />{currentLanguage == "en" ? "English" : "العربية"}
                                <i class={`fa ml-2 ${ !toggleMenu ? "fa-caret-down" : "fa-caret-up"}`} aria-hidden="true"></i>
                              </span>
                            <ul className="languageSwitcher" style={{display: window.innerWidth < 992 ? 'block' :toggleMenu ? 'block' : 'none'}}>
                                <li
                                 onClick={()=>handleChangeLang("en")}
                                ><a href="#">
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/images/en.png"
                                    }
                                    alt=""
                                  />
                                  <span className={`${currentLanguage === 'ara'?'arabicFont' :''}`}>English</span></a>
                                </li>                            
                                <li
                                onClick={()=>handleChangeLang("ara")}>
                                  <a href="#">
                                    <Image
                                      src={
                                        window.location.origin +
                                        "/assets/images/ar.png"
                                      }
                                      alt=""
                                    />
                                    <span className={`${currentLanguage === 'ara'?'arabicFont' :''}`}>العربية</span>
                                  </a>
                                </li>                            
                            </ul>
                        </li>
                        
                    </ul>
                </div>
    
                <div className={ `nav-links ${ currentLanguage=='ara' ? 'order-2 order-lg-1' : 'order-1 order-lg-2'} `}>
                    <a href="/auth/signup" className={`account-btn ${currentLanguage === 'ara'?'arabicFont' :''}`}>{t("create_account")}</a>
                    <a href="/auth/login" className={`login-btn ${currentLanguage === 'ara'?'arabicFont' :''}`}>{t("login")}</a>
                </div>
            </Container>
        </Nav>

        <Container >
            <Row className="align-items-center justify-content-center" style={{direction: currentLanguage === 'ara'? 'rtl': 'ltr'}}>
                <Col 
                  lg={6}
                  xl={6}
                  md={12}
                  sm={12}
                  xs={12}
                 className={ window.innerWidth < 992 ? 'order-2': ''}
                >
                    <h1  className={`${currentLanguage === 'ara'?'arabicFont text-lg-right' :''}`}>{t("welcome_creators")}</h1>
                    <h2  className={`${currentLanguage === 'ara'?'arabicFont text-lg-right' :''}`}>{t("welcome_creators_h2")} <span href="#" className="d-block d-lg-inline">{t("welcome_creators_sayHello")}</span></h2>
                    <div className="ratio ratio-16x9 d-flex d-lg-none">
                        { currentLanguage ==='ara' ?
                            <iframe src="https://www.youtube.com/embed/EePrv9ZHFtk" title="YouTube video"allowfullscreen></iframe>
                            :
                            <iframe src="https://www.youtube.com/embed/FQZ7Bv60LdY?rel=0" title="YouTube video" allowFullScreen></iframe>

                        }
                    </div>
                    <p  className={`${currentLanguage === 'ara'?'arabicFont text-lg-right' :''}`}>{t("welcome_creators_p1")}</p>
                    <p  className={`${currentLanguage === 'ara'?'arabicFont text-lg-right' :''}`}>{t("welcome_creators_p2")}</p>
                </Col>
                <Col 
                  lg={6}
                  xl={6}
                  md={12}
                  sm={12}
                  xs={12}
                  className={ window.innerWidth < 992 ? 'order-1': ''}
                >
                    <Image src="assets/images/logo.png"  alt="ALVIP"/>
                </Col>
            </Row>
        </Container>
      </header>

      <section className="intro-section">
          <div className="container">
              <div className="row align-items-center justify-content-center" >

                  <div className="col-lg-6 videoContainer d-none d-lg-flex">
                      <div className="ratio ratio-16x9">
                        { currentLanguage ==='ara' ?
                            <iframe src="https://www.youtube.com/embed/EePrv9ZHFtk" title="YouTube video"allowfullscreen></iframe>
                            :
                            <iframe src="https://www.youtube.com/embed/1InGV1h_S-Q" title="YouTube video" allowFullScreen></iframe>

                        }
                      </div>
                  </div>

                  <div className="col-lg-6 textContainer">
                      <h2 className={`${currentLanguage === 'ara'?'arabicFont text-lg-right' :''}`}>{t("intro_h2")}</h2>
                      <p className={`${currentLanguage === 'ara'?'arabicFont text-lg-right' :''}`}>{t("intro_p1")} </p>
                      <p className={`${currentLanguage === 'ara'?'arabicFont text-lg-right' :''}`}>{t("intro_p2")}</p>
                      <p className={`${currentLanguage === 'ara'?'arabicFont text-lg-right' :''}`}>{t("intro_p3")}</p>
                      <p className={`${currentLanguage === 'ara'?'arabicFont text-lg-right' :''}`}>{t("intro_p4")}</p>
                      <p className={` mb-5 ${currentLanguage === 'ara'?'arabicFont text-lg-right' :''}`}>{t("intro_p5")}</p>
                  </div>
              </div>
          </div>
      </section>

      <section className="info-section">
          <div className="container">
              <div className="row align-items-center justify-content-center">
                  <div className="col-lg-6 order-2 order-lg-1">
                      <h2 className={`${currentLanguage === 'ara'?'arabicFont text-lg-right' :''}`}>{t("info_h2")}</h2>
                      <p className={`${currentLanguage === 'ara'?'arabicFont text-lg-right' :''}`}>{t("info_p")}</p>
                  </div>
                  <div className="col-lg-6 order-1 order-lg-2">
                      <Image src="assets/images/influencers.png" alt="Influencers"/>
                  </div>
              </div>
          </div>
      </section>

      <section className="numbers-section">
          <div className="container">
              <div className="row justify-content-center">
                  <div className="col-lg-6 text-center">
                      <h2 className={`${currentLanguage === 'ara'?'arabicFont' :''}`}>{t("numbers_h2")}</h2>

                      <p className={`${currentLanguage === 'ara'?'arabicFont' :''}`}>{t("numbers_h2_p")}</p>
                  </div>
              </div>
              <div className="row">                        
                  <div className="col-lg-3 col-sm-6 nm-item">
                      <Image src="assets/images/subscription.png" alt="Subscription"/>
                      <h3 className={`${currentLanguage === 'ara'?'arabicFont' :''}`}>{t("numbers_h3_1")}</h3>
                      <p className={`${currentLanguage === 'ara'?'arabicFont' :''}`}>{t("numbers_h3_p1")}</p>
                  </div>

                  <div className="col-lg-3 col-sm-6 nm-item">
                      <Image src="assets/images/live.png" alt="Live"/>
                      <h3 className={`${currentLanguage === 'ara'?'arabicFont' :''}`}>{t("numbers_h3_2")}</h3>
                      <p className={`${currentLanguage === 'ara'?'arabicFont' :''}`}>{t("numbers_h3_p2")}</p>
                  </div>

                  <div className="col-lg-3 col-sm-6 nm-item">
                      <Image src="assets/images/influencer.png" alt="Influencer"/>
                      <h3 className={`${currentLanguage === 'ara'?'arabicFont' :''}`}>{t("numbers_h3_3")}</h3>
                      <p className={`${currentLanguage === 'ara'?'arabicFont' :''}`}>{t("numbers_h3_p3")}</p>
                  </div>

                  <div className="col-lg-3 col-sm-6 nm-item">
                      <Image src="assets/images/verified.png" alt="Verified"/>
                      <h3 className={`${currentLanguage === 'ara'?'arabicFont' :''}`}>{t("numbers_h3_4")}</h3>
                      <p className={`${currentLanguage === 'ara'?'arabicFont' :''}`}>{t("numbers_h3_p4")}</p>
                  </div>
              </div>
          </div>
      </section>


      <section className="add-section">
          <div className="container">
              <div className="row align-items-center justify-content-center">

                  <div className="col-lg-6">
                      <Image src="assets/images/circle.png" alt="Circle"/>
                  </div>
                  <div className="col-lg-6">
                      <h2  className={`${currentLanguage === 'ara'?'arabicFont text-lg-right' :''}`}>{t("add_h2")}</h2>
                      <ul className={`${currentLanguage === 'ara'?'arabicFont text-right arabicList' :''}`} style={ {direction: currentLanguage === 'ara'? 'rtl':'ltr'}}>
                          <li><Image src="assets/images/check.png" alt="Check" style={{marginLeft: currentLanguage === 'ara'? '20px' : 'auto'}}/> {t("add_li1")}</li>
                          <li><Image src="assets/images/check.png" alt="Check" style={{marginLeft: currentLanguage === 'ara'? '20px' : 'auto'}}/> {t("add_li2")}</li>
                          <li><Image src="assets/images/check.png" alt="Check" style={{marginLeft: currentLanguage === 'ara'? '20px' : 'auto'}}/> {t("add_li3")}</li>
                          <li><Image src="assets/images/check.png" alt="Check" style={{marginLeft: currentLanguage === 'ara'? '20px' : 'auto'}}/> {t("add_li4")}</li>
                      </ul>
                  </div>
              </div>
          </div>
      </section>

      <section className="box-section">
          <div className="container-fluid">
              <div className="row">
                  <h2 className={`${currentLanguage === 'ara'?'arabicFont' :''}`}>{t("box_h2")}</h2>
              </div>
          </div>

          <div className="container pt-5 pb-5">
              <div className="row justify-content-around">
                  <div className="col-lg-4 mt-5 mb-5">
                      <div className="box">
                          <Image src="assets/images/focus.png" alt="Focus"/>
                          <h3 className={`${currentLanguage === 'ara'?'arabicFont' :''}`}>{t("box_h31")}</h3>
                          <p className={`${currentLanguage === 'ara'?'arabicFont' :''}`}>{t("box_h3p1")}</p>
                      </div>
                  </div>

                  <div className="col-lg-4 mt-5 mb-5">
                      <div className="box">
                          <Image src="assets/images/aim.png" alt="Aim"/>
                          <h3 className={`${currentLanguage === 'ara'?'arabicFont' :''}`}>{t("box_h32")}</h3>
                          <p className={`${currentLanguage === 'ara'?'arabicFont' :''}`}>{t("box_h3p2")}</p>
                      </div>
                  </div>
              </div>
          </div>
      </section>


      <section className="list-section">
          <div className="container">
              <div className="row">
                  <div className="row align-items-center justify-content-center">
                      <div className="col-lg-12">
                          <h2 className={`${currentLanguage === 'ara'?'arabicFont' :''}`}>{t("list_h2")}</h2>
                          <p className={`${currentLanguage === 'ara'?'arabicFont' :''}`}>{t("list_h2p")}</p>
                      </div>

                      <div className="col-lg-5 mt-5 mt-lg-2">
                          <Image src="assets/images/interaction.png" alt="Social Media Interaction"/>
                      </div>

                      <div className="col-lg-7">
                          <div className="row">
                              <div className="col-sm-6">
                                  <ul className={`${currentLanguage === 'ara'?'arabicFont text-lg-right' :''}`} style={ {direction: currentLanguage === 'ara'? 'rtl':'ltr'}}>
                                      <li><Image src="assets/images/check.png" alt="Check" style={{marginLeft: currentLanguage === 'ara'? '20px' : '15xp'}}/> {t("list_li1")}</li>
                                      <li><Image src="assets/images/check.png" alt="Check" style={{marginLeft: currentLanguage === 'ara'? '20px' : '15xp'}}/> {t("list_li2")}</li>
                                      <li><Image src="assets/images/check.png" alt="Check" style={{marginLeft: currentLanguage === 'ara'? '20px' : '15xp'}}/> {t("list_li3")}</li>
                                      <li><Image src="assets/images/check.png" alt="Check" style={{marginLeft: currentLanguage === 'ara'? '20px' : '15xp'}}/> {t("list_li4")}</li>
                                  </ul>
                              </div>

                              <div className="col-sm-6">
                                  <ul className={`${currentLanguage === 'ara'?'arabicFont text-lg-right' :''}`} style={ {direction: currentLanguage === 'ara'? 'rtl':'ltr'}}>
                                      <li><Image src="assets/images/check.png" alt="Check" style={{marginLeft: currentLanguage === 'ara'? '20px' : '15xp'}}/> {t("list_li5")}</li>
                                      <li><Image src="assets/images/check.png" alt="Check" style={{marginLeft: currentLanguage === 'ara'? '20px' : '15xp'}}/> {t("list_li6")}</li>
                                      <li><Image src="assets/images/check.png" alt="Check" style={{marginLeft: currentLanguage === 'ara'? '20px' : '15xp'}}/> {t("list_li7")}</li>
                                      <li><Image src="assets/images/check.png" alt="Check" style={{marginLeft: currentLanguage === 'ara'? '20px' : '15xp'}}/> {t("list_li8")}</li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>


      <section className="footer-section">

          <div className="container">
              <div className="row justify-content-center">
                  <div className="col-lg-12">
                      <h2 className={`${currentLanguage === 'ara'?'arabicFont' :''}`}>{t("footer_h2")}</h2>
                      <p className={`${currentLanguage === 'ara'?'arabicFont' :''}`}>{t("footer_h2_p")}</p>
                  </div>

                  <div className="col-lg-3 col-sm-6">
                      <Image src="assets/images/fans.png" alt="Fans"/>
                      <h3 className={`${currentLanguage === 'ara'?'arabicFont' :''}`}>{t("footer_h31")}</h3>
                      <p className={`${currentLanguage === 'ara'?'arabicFont' :''}`}>{t("footer_h3p1")}</p>
                  </div>  

                  <div className="col-lg-3 col-sm-6">
                      <Image src="assets/images/people.png" alt="People"/>
                      <h3 className={`${currentLanguage === 'ara'?'arabicFont' :''}`}>{t("footer_h32")}</h3>
                      <p className={`${currentLanguage === 'ara'?'arabicFont' :''}`}>{t("footer_h3p2")}</p>
                  </div>   
                  
                  <div className="col-lg-3 col-sm-6">
                      <Image src="assets/images/post.png" alt="Post"/>
                      <h3 className={`${currentLanguage === 'ara'?'arabicFont' :''}`}>{t("footer_h33")}</h3>
                      <p className={`${currentLanguage === 'ara'?'arabicFont' :''}`}>{t("footer_h3p3")}</p>   
                  </div> 
                  
                  <div className="col-lg-3 col-sm-6">
                      <Image src="assets/images/item.png" alt="Item"/>
                      <h3 className={`${currentLanguage === 'ara'?'arabicFont' :''}`}>{t("footer_h34")}</h3>
                      <p className={`${currentLanguage === 'ara'?'arabicFont' :''}`}>{t("footer_h3p4")}</p>
                  </div> 
              </div>
          </div>

      </section>


      <section className="copyright-section" style={ {direction: currentLanguage === 'ara'? 'rtl':'ltr'}}>
          <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-md-10 col-lg-8">
                    <p>{t("footer_row1")}</p>
                    <p>{t("footer_row2")}</p> 
                    <p><a href="/page/terms">{t("terms_of_service")}</a>    |    <a href="/page/privacy">{t("privacy_policy")}</a></p>

                </div>
            </div>
          </div>
      </section>
    </>
  );
};
const mapStateToPros = (state) => ({

});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(MainPageNew));


