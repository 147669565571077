import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Container, Row, Col, Image } from "react-bootstrap";
import configuration from "react-global-configuration";
import SocialButton from "../helper/SocialButton";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import {
  forgotPasswordStart,
  userLoginStart,
  userRegisterStart,
  userContactStart,
  usernameValidationStart,
  referralValidationStart,
} from "../../store/actions/UserAction";
import { getErrorNotificationMessage } from "../helper/NotificationMessage";
import { createNotification } from "react-redux-notify";
import {
  isAndroid,
  isIOS,
  isWindows,
  isMacOs,
  mobileModel,
  browserName,
  osName,
  mobileVendor,
  browserVersion
} from "react-device-detect";
import { getFcmToken,onMessageListener } from '../../firebase';

const ContactPageIndex = (props) => {
  const [show, setShow] = useState("login");

  const [contactInputData, setContactInputData] = useState({
    email: configuration.get("configData.demo_user_email"),
    name: '',
    phone: '',
    message: '',
    country: '',
  });

  const [signupInputData, setSignupInputData] = useState({
    referral_code: "",
    device_type: "",
    device_model: "",
    browser_type: "",
  });

  const [isTokenFound, setTokenFound] = useState(false);  

  const [deviceToken, setDeviceToken] = useState();

  useEffect(() => {
    const referral = '';
    if(configuration.get("configData.is_referral_enabled") == 1) {
      const query = new URLSearchParams(props.location.search);
      const referral = query.get("referral");      

      if(referral) 
        setShow("signup");
    }

    var device_type = "";
    var device_model = "";
    var browser_type = browserName;

    if(isAndroid==true){
      device_type = "android";
      device_model = mobileModel;
    } else if(isIOS==true){
      device_type = "ios";
      device_model = mobileModel;
    } else {
      device_type = "web";
      device_model = browserName+' '+browserVersion;
    }
    
    setSignupInputData({
      ...signupInputData,
      referral_code: referral ? referral : '',
      device_type: device_type,
      device_model: device_model,
      browser_type: browser_type,
    });
  }, []);

  const [forgotPasswordInputData, setForgotPasswordInputData] = useState({});

  const [validationError, setValidationError] = useState("NO");

  useEffect(() => {

    var device_type = "";
    var device_model = "";
    var browser_type = browserName;

    if(isAndroid==true){
      device_type = "android";
      device_model = mobileModel;
    } else if(isIOS==true){
      device_type = "ios";
      device_model = mobileModel;
    } else {
      device_type = "web";
      device_model = browserName+' '+browserVersion;
    }

    setContactInputData({
      ...contactInputData,
      device_type: device_type,
      device_model: device_model,
      browser_type: browser_type,
    })
  }, []);

  useEffect(() => {
    let data;
    async function tokenFunc() {
      data = await getFcmToken(setTokenFound);
      setContactInputData({
        ...contactInputData,
        device_token: data || "Permission denied by client",
      })
      setSignupInputData({
        ...signupInputData,
        device_token: data,
      });
      setDeviceToken(data);
      return data;
    }
    tokenFunc();
  }, [setTokenFound]);

  const handleContact = (event) => {
    event.preventDefault();
    console.log(contactInputData)
    props.dispatch(userContactStart(contactInputData));
  };

  const handleSignup = (event) => {
    event.preventDefault();
    props.dispatch(userRegisterStart(signupInputData));
  };

  const handleForgotPassword = (event) => {
    event.preventDefault();
    props.dispatch(forgotPasswordStart(forgotPasswordInputData));
  };

  const handleFacebookLogin = (user) => {
    console.log("handleFacebookLogin", user._profile);
    props.dispatch(
      userRegisterStart({
        name: user._profile.name,
        first_name: user._profile.firstName ? user._profile.firstName : "",
        last_name: user._profile.lastName ? user._profile.lastName : "",
        email: user._profile.email ? user._profile.email : "",
        social_unique_id: user._profile.id,
        picture: user._profile.profilePicURL,
        login_by: "facebook",
        device_token: deviceToken,
      })
    );
  };

  const handleGoogleLogin = (user) => {
    console.log("handleGoogleLogin", user._profile);
    props.dispatch(
      userRegisterStart({
        name: user._profile.name,
        email: user._profile.email,
        first_name: user._profile.firstName ? user._profile.firstName : "",
        last_name: user._profile.lastName ? user._profile.lastName : "",
        social_unique_id: user._profile.id,
        picture: user._profile.profilePicURL,
        login_by: "google",
        device_token: deviceToken,
      })
    );
  };

  const handleUsernameValidation = (event, username) => {
    setSignupInputData({
      ...signupInputData,
      username: username,
    });
    props.dispatch(usernameValidationStart({ username: username }));
  };

  const handleSocialLoginFailure = (err) => {
    console.error(err);
  };

  const checkReferralCode = (event) => {
    event.preventDefault();

    if (signupInputData.referral_code) {
      props.dispatch(referralValidationStart({ referral_code: signupInputData.referral_code }));
    } else {
      const notificationMessage = getErrorNotificationMessage('Please enter the Referral code');
			props.dispatch(createNotification(notificationMessage));
    }
  };

  return (
    <>
      <div className="contact-section">
        <Container>
          <Row>
            <Col
              lg={6}
              xl={6}
              md={12}
              sm={12}
              xs={12}
              className="hidden-xs iphone-slide-area resp-btm-lg flex-49"
            >
              <div className="auth-img-left-sec">
                <Image
                  src={
                    window.location.origin +
                    "/assets/images/auth-img.jpg"
                  }
                  alt=""
                  className="auth-left-img"
                />
              </div>
            </Col>
            <Col lg={6} xl={6} md={12} sm={12} xs={12}>
              <div className="sign-in form-section">
                <Link to="#" aria-current="page" className="sign-in-logo">
                  <Image
                    src={configuration.get("configData.site_logo")}
                    width="237"
                  />
                </Link>
               
                <div className="forms-fields">
                  {configuration.get("configData.FB_CLIENT_ID") ? (
                    <SocialButton
                      provider="facebook"
                      appId={configuration.get("configData.FB_CLIENT_ID")}
                      onLoginSuccess={handleFacebookLogin}
                      onLoginFailure={handleSocialLoginFailure}
                      className="social-button"
                      id="facebook-connect"
                    >
                      <span>
                        {t("signup")} / {t("login_with_facebook")}
                      </span>
                    </SocialButton>
                  ) : (
                    ""
                  )}

                  {configuration.get("configData.GOOGLE_CLIENT_ID") ? (
                    <SocialButton
                      provider="google"
                      key={"google"}
                      appId={configuration.get("configData.GOOGLE_CLIENT_ID")}
                      onLoginSuccess={handleGoogleLogin}
                      onLoginFailure={handleSocialLoginFailure}
                      className="social-button"
                      id="google-connect"
                    >
                      <span>
                        {t("signup")} / {t("login_with_google")}
                      </span>
                    </SocialButton>
                  ) : (
                    ""
                  )}
                  {/* <Link to="#" className="social-button" id="twitter-connect">
                    <span>Sign Up / Login with Twitter</span>
                  </Link>
                  <Link to="#" className="social-button" id="google-connect">
                    <span>Sign Up / Login with Google</span>
                  </Link> */}

                  {configuration.get("configData.GOOGLE_CLIENT_ID") ||
                  configuration.get("configData.FB_CLIENT_ID") ? (
                    <span className="or-line">
                      <span>or</span>
                    </span>
                  ) : (
                    <span classsName="login-or-hide"></span>
                  )}
                  <div id="main">
                    <div id="first">
                     
                        <Form
                          onSubmit={handleContact}
                          method="post"
                          autoComplete="off"
                        >
                          <Form.Group controlId="contactName">
                            <Form.Control
                              type="text"
                              controlId="contactName"
                              placeholder="Full name"
                              required
                              value={contactInputData.name}
                              name="name"
                              autoComplete="nope"
                              onChange={(event) =>
                                setContactInputData({
                                  ...contactInputData,
                                  name: event.currentTarget.value,
                                })
                              }
                            />
                          </Form.Group>
                          <Form.Group controlId="contactEmail">
                            <Form.Control
                              type="text"
                              controlId="contactEmail"
                              placeholder="E-mail"
                              required
                              value={contactInputData.email}
                              name="email"
                              autoComplete="nope"
                              onChange={(event) =>
                                setContactInputData({
                                  ...contactInputData,
                                  email: event.currentTarget.value,
                                })
                              }
                            />
                          </Form.Group>

                          <Form.Group controlId="phone">
                            <Form.Control
                              type="text"
                              controlId="phone"
                              placeholder="Phone Number"
                              required
                              value={contactInputData.phone}
                              name="phone"
                              autoComplete="nope"
                              onChange={(event) =>
                                setContactInputData({
                                  ...contactInputData,
                                  phone: event.currentTarget.value,
                                })
                              }
                            />
                          </Form.Group>
                         <Form.Group controlId="country">
                          <Form.Control
                            type="text"
                            controlId="country"
                            placeholder="Country"
                            required
                            value={contactInputData.country}
                            name="country"
                            autoComplete="nope"
                            onChange={(event) =>
                              setContactInputData({
                                ...contactInputData,
                                country: event.currentTarget.value,
                              })
                            }
                          />
                        </Form.Group>
                         <Form.Group controlId="message">
                          <Form.Control
                            type="textarea"
                            style={{height: 'auto !important'}}
                            as="textarea"
                            rows={5}
                            controlId="message"
                            placeholder="Message"
                            required
                            value={contactInputData.message}
                            name="message"
                            autoComplete="nope"
                            onChange={(event) =>
                              setContactInputData({
                                ...contactInputData,
                                message: event.currentTarget.value,
                              })
                            }
                          />
                        </Form.Group>

                          <div className="">
                            <Button
                              id="login"
                              type="submit"
                              onClick={handleContact}
                              className="btn gradient-btn gradientcolor"
                              disabled={props.login.buttonDisable}
                            >
                              Send
                            </Button>
                          </div>
                    
                        </Form>
                     
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  login: state.users.loginInputData,
  contact: state.users.contactInputData,
  signup: state.users.registerInputData,
  forgotPassword: state.users.forgotPasswordInputData,
  validation: state.users.validationInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(ContactPageIndex));
