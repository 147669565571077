import React from "react"
import ContentLoader from "react-content-loader"

const HomeLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={1100}
    height={1150}
    viewBox="0 0 1200 1150"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="31" y="130" rx="0" ry="0" width="0" height="9" /> 
    <rect x="10" y="20" rx="0" ry="0" width="200" height="15" /> 
    <circle cx="1188" cy="31" r="11" /> 
    <rect x="8" y="50" rx="0" ry="0" width="1200" height="4" /> 
    <circle cx="44" cy="114" r="35" /> 
    <rect x="100" y="95" rx="0" ry="0" width="90" height="10" /> 
    <rect x="100" y="114" rx="0" ry="0" width="130" height="9" /> 
    <rect x="670" y="98" rx="0" ry="0" width="35" height="7" /> 
    <rect x="720" y="98" rx="0" ry="0" width="19" height="7" /> 
    <rect x="8" y="156" rx="0" ry="0" width="730" height="15" /> 
    <rect x="8" y="194" rx="5" ry="5" width="730" height="306" /> 
    <circle cx="25" cy="533" r="13" /> 
    <circle cx="63" cy="533" r="13" /> 
    <circle cx="103" cy="533" r="13" /> 
    <rect x="124" y="530" rx="0" ry="0" width="57" height="8" /> 
    <rect x="16" y="560" rx="0" ry="0" width="122" height="8" /> 
    <rect x="8" y="580" rx="0" ry="0" width="730" height="3" /> 
    <circle cx="45" cy="650" r="35" /> 
    <rect x="101" y="631" rx="0" ry="0" width="90" height="10" /> 
    <rect x="101" y="650" rx="0" ry="0" width="130" height="9" /> 
    <rect x="670" y="633" rx="0" ry="0" width="35" height="7" /> 
    <rect x="720" y="633" rx="0" ry="0" width="19" height="7" /> 
    <rect x="11" y="692" rx="0" ry="0" width="730" height="15" /> 
    <rect x="11" y="730" rx="5" ry="5" width="730" height="306" /> 
    <circle cx="26" cy="1069" r="13" /> 
    <circle cx="64" cy="1069" r="13" /> 
    <circle cx="104" cy="1069" r="13" /> 
    <rect x="125" y="1066" rx="0" ry="0" width="57" height="8" /> 
    <rect x="17" y="1096" rx="0" ry="0" width="122" height="8" /> 
    <rect x="11" y="1116" rx="0" ry="0" width="730" height="3" /> 
    <rect x="710" y="520" rx="0" ry="0" width="29" height="22" /> 
    <rect x="710" y="1054" rx="0" ry="0" width="29" height="22" /> 
    <rect x="800" y="80" rx="0" ry="0" width="200" height="16" /> 
    <rect x="1150" y="78" rx="0" ry="0" width="19" height="19" /> 
    <rect x="800" y="117" rx="5" ry="5" width="400" height="99" /> 
    <rect x="1180" y="78" rx="0" ry="0" width="19" height="19" /> 
    <rect x="800" y="239" rx="5" ry="5" width="400" height="99" /> 
    <rect x="800" y="362" rx="5" ry="5" width="400" height="99" />
  </ContentLoader>
)

export default HomeLoader;
